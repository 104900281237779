    .cstm-chatbox-chip {
        background: rgb(29, 28, 28);
        border-radius: 10px !important;
        margin: 4px 4px;
        padding: 4px 16px !important;
        color: #fffafa;
        display: inline;
        cursor: pointer;

    }

    .bot-header {
        background: #000;
        border-radius: 20px 20px 0 0;
        min-height: 60px;
        color: #4BC641;
    }

    .bot-headercstmboard {
        background: #1e2d1e;
        padding: 1.5rem 1rem 0;
        border-radius: 18px 18px 0 0;

    }

    .bot-header-title {
        font-size: 17px;
        font-weight: 700;
        font-family: 'Courier New', Courier, monospace;
        padding: 20px 18px 0px 19px
    }

    .bot-headercstmboard-title {
        font-size: 17px;
    }

    .botShortcut {
        position: fixed;
        cursor: pointer;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #565656;
        color: white;
        bottom: 20px;
        right: 20px;
        font-size: 30px;
    }

    .botShortcut:hover {
        transform: scale(1.2);
    }

    .botShortcut-cstm {
        position: fixed;
        cursor: pointer;
        width: 60px;
        height: 60px;
        background: #00f008a1;
        color: white;
        bottom: 20px;
        right: 20px;
        font-size: 30px;
        padding: 10px;
        border-radius: 50%;
        text-align: center;
        line-height: 60px;
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
        transition: transform 0.2s ease;
        /* Add a smooth transition for the scaling effect */
    }

    .botShortcut-cstm::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        background: #00f008a1;
        border-radius: 50%;
    }

    .botShortcut-cstm:hover {
        transform: scale(1.2);
        animation: bounce 0.5s ease infinite;
        /* Add the bounce animation on hover */
        height: 60px;
        background: #00f008d3;
    }

    .background-00f008d3 {
        background-color: #00f008d3 !important;
    }

    .cursor-pointer {
        cursor: pointer !important;
    }

    @keyframes bounce {
        0%, 100% {
            transform: translateY(0);
            /* Initial and final position, no translation */
        }

        50% {
            transform: translateY(-10px);
        }
    }

    .botAnimie {
        transition: bottom 0.2s ease-in;
        /* animation: animateDiv 0.2s; */

    }

    @keyframes animateDiv {
        0% {
            bottom: 0px;

        }

        100% {
            bottom: 135px;

        }
    }