$bodyBackground: #121d12;
$headerBackground: #121d12;
$cardBackground: #c3dcc0;
$innerBodyBackground: #fff;
$inputBackground: #fff;
$connectnowBackground: #fff;
$iconColor: #070707;
$subTextColor: #0000;
$headingColor: #121d12;
$textColor: #0000;
$landingPageHeading: #959595;
$black: #000000;
$white: #ffff;
$chatHeaderBackground: #121414;
$adminButtonText: #fff;
$connectnowBackgroundOnhover: #4bc641;
$connectnowText: #121d12;
$chatPanelBackground: #004d7141;
$categoryIconBackground: #4bc641;
$headingColorWhite: #ffff;
.loader-wrp {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1056;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
}

.types-table {
  th,
  td {
    min-width: auto;
  }
}

.answers-list {
  padding: 17px 0;
}

.rank-list {
  padding: 1px 0;
  display: flex;
  padding-bottom: 22px;
  // padding-top: 1px;
}
.rank-list-more {
  padding: 1px 0;
  display: flex;
  padding-bottom: 30px;
  padding-top: 1px;
}

.approve-list {
  // padding: 8px 0;
  padding-bottom: 7px;
}
.approve-list-more {
  // padding: 8px 0;
  padding-bottom: 15px;
}
.datasource-list-more {
  padding: 8px 0;
}
.datasource-list {
  padding: 3px 0;
}

.add-btn {
  min-width: 100px;
}
.read-or-hide {
  color: blue;
  cursor: pointer;
}
.card {
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
}
.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.toast {
  display: block;
}
.toast:first-letter {
  text-transform: capitalize;
}
.cstm-title {
  color: $black;

  text-align: center;
  font-weight: 600;
  // text-decoration: underline;
}
.card_cstm_landing {
  text-align: center;
  color: $black;
}
.card-description {
  color: $black;
}
.example-box {
  background: $headingColor;
  padding: 0.5rem;
  text-align: center;
  height: 100%;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.example-box p {
  color: #fff;
}
.header-section {
  background: $bodyBackground;
  padding: 100px 0;
  height: auto;
}

.header-section .hero-img {
  color: transparent;
  -o-object-fit: contain;
  object-fit: contain;
  max-height: 400px;
}

.header-section .title {
  color: $landingPageHeading;
}
.content-menus {
  margin-top: -50px;
  position: relative;
  z-index: 1;
}

.content-menus .menu-boxes {
  padding: 1.5rem 1rem;
  border-radius: 8px;
  background: $cardBackground;
  height: 100%;
  text-align: center;
  min-height: 200px;
  display: block;
  text-decoration: none;
  color: $black;
  -webkit-box-shadow: 0px 0px 1.125rem 0.25rem rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 1.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

.content-menus .menu-boxes p {
  margin-bottom: 0;
}

.content-menus .menu-boxes .icon-box {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin: -3rem auto 1rem auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: $categoryIconBackground;
}

.content-menus .menu-boxes .icon-box img {
  max-width: 40px;
}

.content-menus .menu-boxes h5,
.content-menus .menu-boxes .h5 {
  font-weight: 600;
}
.connect {
  padding: 10px 15px;
  background: $connectnowBackground;
  color: $connectnowText;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-decoration: none;
  -webkit-transition: all 250ms;
  transition: all 250ms;
  border-radius: 52px;
}

.connect:hover {
  background: $connectnowBackgroundOnhover;
  color: $black;
  border: 1px solid black;
}
.main-body-div {
  background-color: #fff;
}
.ewmb-header {
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  width: 100%;
  z-index: 101;
  transition: all 0.25s ease;
}
.text-home {
  color: $white;
}

.everything-text {
  color: $black;
}
.mainheading {
  color: $headingColorWhite;
  margin-top: 18vh;
}
.brand-name {
  text-align: center;
  margin-top: 19px;
  color: $headingColorWhite;
}

.cstm-icon {
  color: $iconColor;
}
.chatIconDiv {
  width: 25px;
  margin-left: 15px;
  align-items: flex-start;
}
.chatIcon {
  background-color: $iconColor;
  width: fit-content;
  padding: 3px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-panel-loading {
  width: 100%;
  background-color: $chatPanelBackground;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 18px 0;
  border-radius: 5px;
}
.chat-panel {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 18px 0;
  border-radius: 5px;
  z-index: 1;
}
.cstm-header {
  padding: 10px 15px;
  border-radius: 5px;
  background-color: $chatHeaderBackground;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  position: absolute;
  top: 80px;
  width: 100%;
  z-index: 999;
}
.main_cont {
  width: 50%;
  margin: auto;
  height: 100vh;
  position: relative;
  // padding-top: 2.5vh;
}
.botm {
  // display: 'flex',
  // flexDirection: 'column',
  align-items: center;
  // position: absolute;
  bottom: 20px;
  width: 100%;
}
.input_box {
  // width: '86%',
  background-color: white;
  height: 48px;
  border-radius: 6px;
  border-color: #efefef;
  box-shadow: 0px 0px 1.125rem 0.25rem rgb(0 0 0 / 10%);

  //   marginTop: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.input-area {
  width: 97%;
  height: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  color: black;
  padding-left: 8px;
}
.send-icon {
  margin-right: 2px;
  background-color: transparent;
  outline: none;
  border: none;
}

.text-color-white {
  color: #ffffff !important;
}

@for $i from 0 through 100 {
  .ms-#{$i}px {
    margin-left: #{$i}px !important;
  }
}

.btn-070707 {
  background-color: #070707 !important;
  color: #ffffff !important;
}

.breif-chart {
  background: #121d12;
  padding: 1.5rem;
  border-radius: 18px;
}

.breif-chart-tbl {
  border: 1px solid #383838;
  background: #000000 !important;
  padding: 1.5rem;
  border-radius: 18px;
}

.chat-panel-export {
  width: 100%;
  display: block;
  padding: 18px 0;
  border-radius: 5px;
  z-index: 1;
}

.bg-color-transparent {
  background-color: transparent !important;
}

@media screen and (max-width: 576px) {
  .gap-sm-0 {
    gap: 0rem !important;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
}

@media screen and (min-width: 576px) {
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
}
