@import url(https://fonts.googleapis.com/css?family=Chewy);

:root {
  --card-line-height: 1.2em;
  --card-padding: 1em;
  --card-radius: 0.5em;
  --color-green: #558309;
  --color-gray: #e2ebf6;
  --color-dark-gray: #c4d1e1;
  --radio-border-width: 2px;
  --radio-size: 1.5em;
}

.card-dashboard {
  background-color: white;
  color: black;
  border: 0;
  padding: 32px;
}

.selection__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 12px;
}

.chart-container {
  background: #121d12;
  padding: 18px;
  margin-right: 16px;
  border: 0px solid #cccccc;
  margin-bottom: 16px;
  border-radius: 10px;
  max-width: 400px;
  box-shadow: rgb(28 35 28 / 59%) 0px 30px 60px -12px inset,
    rgb(66 93 47 / 84%) 0px 18px 36px -18px inset;
  width: 400px;
}

.chart-container-cstm {
  background: #121d12;
  padding: 18px;
  margin-right: 16px;
  border: 0px solid #cccccc;
  margin-bottom: 16px;
  border-radius: 10px;
  max-width: 900px;
  box-shadow: rgb(28 35 28 / 59%) 0px 30px 60px -12px inset,
    rgb(66 93 47 / 84%) 0px 18px 36px -18px inset;
  width: 900px;
}

.grid {
  display: grid;
  grid-gap: var(--card-padding);
  margin: 0 auto;
  max-width: 60em;
  padding: 0;

  @media (min-width: 42em) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.modal-card {
  background-color: transparent;
  border-radius: 20px;
  position: relative;
  max-width: 380px;
  max-height: 240px;
  padding: 0;
}

.modal-card:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
}

.radio {
  font-size: inherit;
  margin: 0;
  position: absolute;
  right: calc(var(--card-padding) + var(--radio-border-width));
  top: calc(var(--card-padding) + var(--radio-border-width));
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px;
    background: #fff;
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    /* Change this to the desired radio size */
    outline: none;
    transition: background 0.2s ease-out, border-color 0.2s ease-out;
    width: 20px;
    /* Change this to the desired radio size */
  }

  .radio::after {
    border: var(--radio-border-width) solid #fff;
    border-top: 0;
    border-left: 0;
    content: '';
    display: block;
    height: 0.75rem;
    left: 25%;
    position: absolute;
    top: 50%;
    transform: rotate(45deg) translate(-50%, -50%);
    width: 0.375rem;
  }

  .radio:checked {
    background: var(--color-green);
    border-color: var(--color-green);
  }

  .modal-card:hover .radio {
    border-color: var(--color-dark-gray);
  }

  .modal-card:hover .radio:checked {
    border-color: var(--color-green);
  }
}

.plan-details {
  border: var(--radio-border-width) solid var(--color-gray);
  border-radius: var(--card-radius);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: var(--card-padding);
  transition: border-color 0.2s ease-out;
}

.modal-card:hover .plan-details {
  border-color: var(--color-dark-gray);
}

.radio:checked~.plan-details {
  border-color: var(--color-green);
}

.radio:focus~.plan-details {
  box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio:disabled~.plan-details {
  color: var(--color-dark-gray);
  cursor: default;
}

.radio:disabled~.plan-details .plan-type {
  color: var(--color-dark-gray);
}

.modal-card:hover .radio:disabled~.plan-details {
  border-color: var(--color-gray);
  box-shadow: none;
}

.modal-card:hover .radio:disabled {
  border-color: var(--color-gray);
}

.plan-type {
  color: var(--color-green);
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1em;
}

.plan-cost {
  font-size: 2.5rem;
  font-weight: bold;
  padding: 0.5rem 0;
}

.slash {
  font-weight: normal;
}

.plan-cycle {
  font-size: 2rem;
  font-variant: none;
  border-bottom: none;
  cursor: inherit;
  text-decoration: none;
}

.hidden-visually {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.modal-row {
  display: flex;
  flex-basis: 50%;
  /* Two items in a row, adjust this as needed */
}

.modal-grid {
  display: flex;
  flex-wrap: wrap;
}

.cstm-nav {
  display: flex;
  flex: 1 1;
  cursor: pointer;

  justify-content: center;
  align-items: center;
  border: var(--cui-sidebar-nav-link-border);
  color: var(--cui-sidebar-nav-link-color);
  padding: 1rem 24px;
  text-decoration: none;
  white-space: nowrap;
  border: var(--cui-sidebar-nav-link-border);
  border-radius: var(--cui-sidebar-nav-link-border-radius);
  transition: background 0.15s ease, color 0.15s ease;
}

.cstm-input {
  max-width: 120px;
  background: inherit;
  color: white;
}

.cstm-btn {
  line-height: 1px;
  height: 36px;
}

.cstm-icn {
  height: 22px !important;
  background: #e7e7e6;
  border: 1px solid #ccc;
  width: 22px !important;
  padding: 2px;
  color: black;
  border-radius: 4px;
  cursor: pointer;
}

.title-container {
  max-width: 200px;
  /* Adjust this value according to your design needs */
  white-space: nowrap;
  /* Prevent text from wrapping to a new line */
  overflow: hidden;
  /* Hide any overflowing text */
  text-overflow: ellipsis;
  /* Add an ellipsis (...) to indicate text overflow */
}

.cstm-create-btn {
  border-radius: 10px;
  border: 0;
  padding: 8px 16px;
}

.sidebar-nav-dashboard .nav-link.active {
  background: #959595 !important;
  color: #fff !important;
  padding: 12px !important;
  border-radius: 10px !important;
  margin: 0 6px !important;
}

.sidebar-dashboard {
  background: #121d12;
  box-shadow: 2px 0 5px rgb(0 0 0 / 20%);
}

.cstm-sidebar-new {
  color: black;
}

.cstm-chip {
  background: #3ebfc1 !important;
  color: #fff !important;
  border-radius: 10px !important;
}

.cstm-chip-board {
  background: #4d7753 !important;
  color: #fff !important;
  border-radius: 10px !important;
}

.embed-chart {
  background: #121d12 !important;
  padding: 18px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  width: 50%;
  margin-top: 80px;
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 350px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 370px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #000;
  position: fixed;
  background-color: #121d12;
  width: calc(100% - 130px);
  z-index: 1;
  border-radius: 18px;
  border: solid 1px #2b2b2b;
  transition: all ease 0.25s;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav>.sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav>.sidebar-brand a {
  color: #999999;
}

.sidebar-nav>.sidebar-brand a:hover {
  color: #fff;
  background: none;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 350px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 250px;
    height: calc(-100px + 100vh);
    overflow-x: hidden;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    padding: 20px;
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

.activedatabase {
  background-color: #5e7363ab;
  border-radius: 30px 0px 0px 30px;
}

.cstm-textareachart {
  width: 100%;
  background: transparent;
  color: white;
  border-radius: 10px;
  padding: 10px;
  min-width: 500px;
}

.cstm-text-area {
  background: transparent;
  color: white;
  border-radius: 10px;
  padding: 10px;
}

.slide-in {
  animation: slideRightToLeft 0.5s ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateX(100%);
}

@keyframes slideRightToLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-out {
  animation: slideLeftToRight 1s ease-in-out;
  animation-fill-mode: forwards;
  opacity: 1;
  transform: translateX(0);
}

@keyframes slideLeftToRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.cstm-font {
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
}

.mic-icon {
  position: absolute;
  right: 54px; /* Adjust horizontal position to place it near the send icon */
  /* bottom: -109px;  */
  bottom: 13px;
  background-color: transparent;
  outline: none;
  border: none;
  z-index: 1;
}

.audio-icon {
  margin-right:'5px';
  margin-top:'10px';
  background-color: #070707;
  /* outline: none; */
  border: none;
}


.cstm-send-input {
  background: #000;
  border-radius: 18px;
  height: 54px;
  width: 100%;
  border: 0;
  color: #fff;
  padding: 1rem 4.5rem 1rem 1rem;
  position: inherit;
}

.cstm-send-icon {
  position: absolute !important;
  right: 19px !important;
}

.cstm-background {
  background: #121d12;
  border-bottom-left-radius:1rem;
  border-bottom-right-radius: 1rem;
}

.cstm-textbox {
  background: #0b1f0b;
  border: solid 1px #133213;
  padding: 5px 15px;
  min-height: 50px;
  border-radius: 14px;
  width: 100%;
  color: white;
}

.cstm-textbox:focus {
  color: #fff;
  background-color: #0b1f0b !important;
  border-color: #a5e3a0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #4bc64140;
}

.btn {
  padding: 4px 24px !important;
  border-radius: 100px !important;
  font-size: 14px !important;
}

.cstm-err {
  font-size: 13px;
  color: red;
  margin: 3px 10px 0 8px;
}

.cstm-chartimage {
  background-color: #000;
  max-width: 150px;
  margin: auto;
  width: 100%;
}

@media (max-width: 767.98px) {
  .cstm-chart-title-mb {
    display: block !important;
  }

  .cstm-chart-button-section {
    text-align: end;
    margin-bottom: 16px;
    margin-right: 16px;
  }

  .bot-chatwindow {
    height: 76vh;
    width: 93%;
  }

  .cstm-slide-in {
    position: fixed;
    right: 0;
    bottom: 135px;
  }

  .cstm-textareachart {
    width: 100%;
    background: transparent;
    color: white;
    border-radius: 10px;
    padding: 10px;
    min-width: 20px !important;
  }

  .cstm-flex-mb {
    display: flex;
  }

  .embed-chart {
    background: #121d12 !important;
    padding: 18px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    width: 100% !important;
    margin-top: 80px;
  }
}

@media (min-width: 767.98px) {
  .bot-chatwindow {
    /* height: 70vh; */
    height: calc(100vh - 296px);
    width: 420px;
  }

  .cstm-slide-in {
    position: fixed;
    right: 17px;
    bottom: 135px;
  }
}

.cstm-qstn-array {
  color: #fff;
  margin-right: 8px;
  padding: 4px 8px;
  cursor: pointer;
  background: #4d7753 !important;
  color: #fff !important;
  border-radius: 10px !important;
}

.question-box {
  background: #000;
  padding: 1rem;
  border-radius: 8px;
  border: solid 1px #2d2d2d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 100%;
}

.question-box p {
  margin-right: 10px;
}

.send-ques img {
  opacity: 0.5;
}

.clear-image {
  padding: 0;
  cursor: pointer;
}

/* Table Styles */
.cstm-table-org {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #ccc;
  margin: 20px 0;
  font-family: Arial, sans-serif;
  border-radius: 10px;
}

/* Table Header Styles */
.cstm-thead-org {
  background-color: #333;
  color: #fff;
}

.cstm-th-org,
.cstm-td-org {
  padding: 12px;
  text-align: left;
}

/* Table Header Cell Styles */
.cstm-th-org {
  font-weight: bold;
  border-bottom: 2px solid #fff;
}

.MuiTable-root {
  background: #121d12;
}

.MuiTableCell-root {
  color: #fff !important;
}

.MuiTableCell-head {
  color: #fff !important;
}

.MuiButtonBase-root {
  color: #fff !important;
}

.MuiInputBase-root {
  color: #fff !important;
}

.cstm-dept {
  padding: 20px;
  margin-bottom: 26px;
  background: #5d5a5a;
}

.brief-cstm {
  background: #121d12;
  padding: 1.5rem;
  border-radius: 18px;
}

.breadcrumb-org {
  background: #121d12;
  padding: 12px 10px 2px 10px;
  margin-bottom: 1rem;
  border-radius: 15px;
}
.breadcrumb-item > a{
  text-decoration: none;
  color:#ffffff;
}

.error {
  color: red;
}

.nodatas {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #383838;
  background: #000;
  border-radius: 18px;
}

.nodata {
  min-height: calc(100vh - 500px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #383838;
  background: #000;
  border-radius: 18px;
}

.btn-add {
  display: flex;
  align-items: center;
  color: #ffffff;
  background-color: #4cc64100;
  border-color: #4bc641;
}

.btn-add:hover {
  color: #000000;
  background-color: #4bc641;
  border-color: #4bc641;
}

.pagination {
  --cui-pagination-padding-x: 0.75rem;
  --cui-pagination-padding-y: 0.375rem;
  --cui-pagination-font-size: 1rem;
  --cui-pagination-color: rgb(255, 255, 255);
  --cui-pagination-bg: #010101;
  --cui-pagination-border-width: 1px;
  --cui-pagination-border-color: #626262;
  --cui-pagination-border-radius: 0.375rem;
  --cui-pagination-hover-bg: #d8dbe07a;
  --cui-pagination-hover-border-color: #c4c9d0;
  --cui-pagination-focus-bg: #d8dbe0;
  --cui-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(50, 31, 219, 0.25);
  --cui-pagination-active-color: rgba(23, 23, 23, 0.87);
  --cui-pagination-active-bg: #4bc641;
  --cui-pagination-disabled-color: #8a93a2;
  --cui-pagination-disabled-bg: #fff;
  --cui-pagination-disabled-border-color: #c4c9d0;
  display: flex;
  list-style: none;
}

.search-cstm {
  height: 40px;
  background: transparent;
  border: 1px solid #285624;
  padding: 6px 16px;
  border-radius: 20px;
  color: #fff;
  width: 0;
  transition: width 0.5s;
}

.search-cstmOrg {
  height: 40px;
  background: transparent;
  border: 1px solid #285624;
  padding: 6px 16px;
  border-radius: 20px;
  color: #fff;
  /* width: 0; */
  transition: width 0.5s;
}

.filter-cstm {
  /* height: 40px;
  background: transparent;
  border: 1px solid #285624;
  padding: 6px 16px;
  border-radius: 20px;
  color: #fff; */
  width: 0;
  transition: width 0.5s;
}



/*  */

.search-icon-cstm {
  position: absolute;
}

.search-cstm-text {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  width: 93%;
}

/* .search-cstm-text-open {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  width: 93%;
  transition: ease-in;
  display: block;
} */

.MuiMenuItem-root {
  background: #000;
}

.MuiList-root {
  background-color: #121d12;
  border: 1px solid #3e5132;
}

.org-chip {
  background: #14660d;
  border-radius: 18px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 8px;
  height: 28px;
}

.org-filter {
  opacity: 0;
  display: block;
  transform: translateX(-100%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.org-filter-visible {
  opacity: 1;
  display: block;
  transform: translateX(0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.wrapper-doc{
  height: 21rem;
  overflow-x: auto;
}

.doc-container {
  background: #121d12;
  margin-bottom: 8px;
  border-radius: 18px;
}

.MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 2.5px 4px 7.5px 5px !important;
}

.MuiOutlinedInput-input {
  background: #0b1f0b !important;
  border: solid 2px #133213 !important;
  border-radius: 14px !important;
  padding: 16px 14px 12px 14px !important;
}

.MuiOutlinedInput-input:focus {
  border: solid 2px #2a582a !important;
}


.MuiInputLabel-root {
  color: #A9A9A9 !important;
  background: #0b1f0b !important;
  padding: 0 8px !important;
  font-size: 14px !important;
}

.MuiAutocomplete-noOptions {
  color: #fff !important;
}

.loadmore {
  display: flex;
  justify-content: center;
  margin: 25px;
}

.loadmorespan {
  width: 150px;
  background: #000000 !important;
  border: solid 2px #2a582a !important;
  text-align: center;
  border-radius: 14px !important;
  cursor: pointer;
}

.loadmorespan:hover {
  width: 150px;
  color: #000;
  background: #3a9631 !important;
  border-color: #4bc641;
  text-align: center;
  border-radius: 14px !important;
  cursor: pointer;
}

.MuiPaper-root {
  background: #121d12 !important;
}

.chartjs-container{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  height: 80vh;
  width: 100%;
}

.chart-item{
  background: #121d12;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
  height: 500px;
}