$bodyBackground: #121D12;
$headerBackground: #121D12;
$cardBackground: #fff;
$innerBodyBackground: #fff;
$inputBackground: #fff;
$connectnowBackground: #ffffff;
$iconColor: #0000;
$subTextColor: #0000;
$homeRefreshBackground: #5f605f41;
$textColor: #0000;
$landingPageHeading:#fff;
$commonBlack:#000000;

$generateButtonBackground:#0C3A08;
$generateButtonText:$cardBackground;

.msg_cont {
    width: 100%;
    margin: auto auto 50px;
    overflow-y: scroll;
  }
  
  .header {
    color: white;
    background-color:$headerBackground ;
    width: 100%;
    padding: 5px 8px;
    cursor: pointer;
  
    .home-refresh-icons {
        color: white;
        background: $homeRefreshBackground;
        width: fit-content;
        padding: 5px 8px;
        border-radius: 4px;
        cursor: pointer;
    }
  
    .refresh-icon {
        color: white;
        background: $homeRefreshBackground;
        width: fit-content;
        padding: 4px 8px 3px 8px;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 10px;
      margin-left: 10px;
    }
  }
  
  .message-container {
    margin-top: 50px;
  }
  
  .chat-panel-loading {
    
    .chatIconDiv {
      .chatIcon {
        img {
          width: 30px;
        }
      }
    }
  
    .spinner-container {
      margin-left: 28px;
      margin-right: 15px;
      width: 100%;
      padding-top: 9px;
      margin-top: 6.5px;
    }
  }
  
  .button-container {
    color: white;
    width: fit-content;
    padding: 5px 8px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 30px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 110px;
  
    .regenerate-button {
      color: $generateButtonText;
      background-color: $generateButtonBackground;
      width: fit-content;
      padding: 8px 20px 10px 8px;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 10px;
      display: flex;
      justify-content: space-between;
    }
  
    .regenerate-icon {
      padding: 7px 6px 4px 8px;
    }
  
    .regenerate-text {
      padding: 8px 14px 1px 8px;
      flex: auto;
    }
  }
  .cstm-msg{
    margin-right: 15px;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    margin-top: 6.5px;
  }

pre{
    white-space: pre-wrap;
}

pre code{
  overflow-wrap: anywhere;
}