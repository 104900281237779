$secondary:#121d12;
.leftside-bar {
  width: 84px;
  flex: 0 0 84px;
  border-radius: 18px;
  position: relative;
  // @include transition(all ease 0.25s);
  position: fixed;
  background: $secondary;
  height: 100%;
  left: 1rem;
  top: 1rem;
  padding: 1rem 0 2rem 0;
  display: flex;
  flex-direction: column;

  transition: all ease 0.25s;
  z-index: 12;
  .logobox {
    img {
      max-width: 100px;
    }
  }
  .sidebar-menu {
    padding-left: 1rem;
    .sidemenu-items {
      padding: 0 0 0.5rem 0;
      font-weight: 600;
      a {
        color: #c4c4c4;
        padding: 1rem 0;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 48px;
        img {
          margin-right: 5px;
        }
      }
      &:first-child {
        padding-top: 0;
        a {
          padding-top: 0.5rem;
        }
      }
      span {
      
        height: 24px;
        overflow: hidden;
      }
    }
  }
  &.open {
    margin-left: 0;
  }
    .logout-sc {
      position: absolute;
      bottom: 49px;
      left: 2px;
      right: 0;
      margin: auto;
  
      a {
        display: flex;
        text-decoration: none;
        align-items: center;
      }
  
      img {
        width: 25px;
      }
  
      span {
        display: none;
      }
    }
}
.openbox {
  .leftside-bar {
    .logout-sc {
      span {
        display: block;
        color: #fff;
        margin-left: 0.5rem;
        font-weight: 600;
      }
    }
  }
}
.arrow-toggle {
  margin-right: 1rem;
  position: absolute;
  right: -71px;
  top: 19px;
  img {
    width: 23px;
  }
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(175, 175, 175);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aeb1aefb;
  border-radius: 10px;
}
// Define the media-breakpoint-down mixin
@mixin media-breakpoint-down($breakpoint) {
  @media (max-width: #{$breakpoint}) {
    @content;
  }
}

// Use the mixin
.leftside-bar {

  .openbox {
    @include media-breakpoint-down(768px) {
      .leftside-bar {
        margin-left: 0px;
      }
    }
  }
}

@media (max-width: 991.98px){
.content-area .chat-section {
    // display: none;
    left:-450px;
}}
@media (max-width: 991.98px){
.content-area .upload-contents {
    width: 100%;
    margin-right: 0;
}}

@media (max-width: 767.98px){
.content-area {
    padding-left: 0;
}}
@media (max-width: 767.98px){
.whizz-header {
    left: 14px;
    width: calc(100% - 30px);
}}
@media (max-width: 767.98px){
.main-content {
    padding: 6.5rem 1rem 2rem 1rem;}
}

@media (max-width: 767.98px){
.openbox .leftside-bar {
    margin-left: 0px;
}
.edit-input{
  max-width: 105px;
}
}

@media (max-width: 767.98px){
.leftside-bar {
    margin-left: -100px;
}}

.menu-selected{
  background: #223f03d6;
  padding: 12px 16px;
  border-radius: 50%;
  margin: 0 10px;
}


.menu-selected-toggle{
  background: #223f03d6;
    padding: 12px 16px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    margin: 0 10px;
}
.cstm-margin{
  margin: 10px 25px;
  display: flex;
}


