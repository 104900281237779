:root {
  --card-line-height: 1.2em;
  --card-padding: 1em;
  --card-radius: 0.5em;
  --color-green: #558309;
  --color-gray: #e2ebf6;
  --color-dark-gray: #c4d1e1;
  --radio-border-width: 2px;
  --radio-size: 1.5em;
}

.card-dashboard {
  background-color: white;
  color: black;
  border: 0;
  padding: 32px;
}

.selection__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 12px;
}

.chart-container {
  background: white;
  padding: 18px;
  margin-right: 16px;
  border: 1px solid #cccccc;
  margin-bottom: 16px;
  border-radius: 10px;
  max-width: 700px;
  width: 700px;
}
.grid {
  display: grid;
  grid-gap: var(--card-padding);
  margin: 0 auto;
  max-width: 60em;
  padding: 0;

  @media (min-width: 42em) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.modal-card {
  background-color: #fff;
  border-radius: var(--card-radius);
  position: relative;
  max-width: 380px;
  max-height: 240px;
  padding: 0;
}

.modal-card:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
}

.radio {
  font-size: inherit;
  margin: 0;
  position: absolute;
  right: calc(var(--card-padding) + var(--radio-border-width));
  top: calc(var(--card-padding) + var(--radio-border-width));
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px;
    background: #fff;
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: 50%;
    cursor: pointer;
    height: 20px; /* Change this to the desired radio size */
    outline: none;
    transition: background 0.2s ease-out, border-color 0.2s ease-out;
    width: 20px; /* Change this to the desired radio size */
  }

  .radio::after {
    border: var(--radio-border-width) solid #fff;
    border-top: 0;
    border-left: 0;
    content: '';
    display: block;
    height: 0.75rem;
    left: 25%;
    position: absolute;
    top: 50%;
    transform: rotate(45deg) translate(-50%, -50%);
    width: 0.375rem;
  }

  .radio:checked {
    background: var(--color-green);
    border-color: var(--color-green);
  }

  .modal-card:hover .radio {
    border-color: var(--color-dark-gray);
  }

  .modal-card:hover .radio:checked {
    border-color: var(--color-green);
  }
}

.plan-details {
  border: var(--radio-border-width) solid var(--color-gray);
  border-radius: var(--card-radius);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: var(--card-padding);
  transition: border-color 0.2s ease-out;
}

.modal-card:hover .plan-details {
  border-color: var(--color-dark-gray);
}

.radio:checked ~ .plan-details {
  border-color: var(--color-green);
}

.radio:focus ~ .plan-details {
  box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio:disabled ~ .plan-details {
  color: var(--color-dark-gray);
  cursor: default;
}

.radio:disabled ~ .plan-details .plan-type {
  color: var(--color-dark-gray);
}

.modal-card:hover .radio:disabled ~ .plan-details {
  border-color: var(--color-gray);
  box-shadow: none;
}

.modal-card:hover .radio:disabled {
  border-color: var(--color-gray);
}

.plan-type {
  color: var(--color-green);
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1em;
}

.plan-cost {
  font-size: 2.5rem;
  font-weight: bold;
  padding: 0.5rem 0;
}

.slash {
  font-weight: normal;
}

.plan-cycle {
  font-size: 2rem;
  font-variant: none;
  border-bottom: none;
  cursor: inherit;
  text-decoration: none;
}

.hidden-visually {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.modal-row {
  display: flex;
  flex-basis: 50%; /* Two items in a row, adjust this as needed */
}

.modal-grid {
  display: flex;
  flex-wrap: wrap;
}

.cstm-nav {
  display: flex;
  flex: 1 1;
  cursor: pointer;

  justify-content: center;
  align-items: center;
  border: var(--cui-sidebar-nav-link-border);
  color: var(--cui-sidebar-nav-link-color);
  padding: 1rem 24px;
  text-decoration: none;
  white-space: nowrap;
  border: var(--cui-sidebar-nav-link-border);
  border-radius: var(--cui-sidebar-nav-link-border-radius);
  transition: background 0.15s ease, color 0.15s ease;
}
.cstm-input {
  max-width: 120px;
  background: inherit;
  color: white;
}
.cstm-btn {
  line-height: 1px;
  height: 36px;
}

.cstm-icn {
  height: 22px !important;
  background: #e7e7e6;
  border: 1px solid #ccc;
  width: 22px !important;
  padding: 2px;
  color: black;
  border-radius: 4px;
  cursor: pointer;
}

.title-container {
  max-width: 200px; /* Adjust this value according to your design needs */
  white-space: nowrap; /* Prevent text from wrapping to a new line */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Add an ellipsis (...) to indicate text overflow */
}

.cstm-create-btn {
  border-radius: 10px;
  border: 0;
  padding: 8px 16px;
}

.sidebar-nav-dashboard .nav-link.active {
  background: #959595 !important;
  color: #fff !important;
  padding: 12px !important  ;
  border-radius: 10px !important;
  margin: 0 6px !important;
}

.sidebar-dashboard {
  background:#121D12;
  box-shadow: 2px 0 5px rgb(0 0 0 / 20%);
}

.cstm-sidebar-new {
  color: black;
}

.cstm-chip{
  background: #3ebfc1 !important;
  color: #fff !important;
  border-radius: 10px !important;
}
.embed-chart{
  background: #121d12;
  padding: 18px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  width: 50%;
}