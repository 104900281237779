@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded');
.chat-container{
    width: 100%;
    max-height: 80vh;
    padding-bottom: 150px;
    overflow-y: auto;
}

:where(.chat-container,textarea)::-webkit-scrollbar{
    width: 6px;
}
:where(.chat-container,textarea)::-webkit-scrollbar-track{
    background: #444654;
    border-radius: 25px;
}

:where(.chat-container,textarea)::-webkit-scrollbar-thumb{
    background: #ACACBE;
    border-radius: 25px;
}

.chat-container .chat{
    padding: 25px 10px;
    display: flex;
    justify-content: center;
}

.chat h3{
    font-size: 1.5rem;
}

.chat-container .chat.outgoing{
    /* background: rgba(95, 96, 95, 0.255);
    border: 1px solid #2f2f2f; */
}
.chat-container .chat.incoming{
    background: transparent;
    border: 1px solid transparent;
}

.chat .chat-content{
    display: flex;
    /* max-width: 1200px; */
    padding-left: 4rem;
    max-width: 915px;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
}

.chat .chat-content span{
  font-size: 1.3rem;
  color: #2f2f2f;
  visibility: hidden;
}

.chat .chat-content:not(:has(.typing-animation)) span{
    visibility: visible;
}

.chat .chat-details{
    display: flex;
    align-items: center;
}

.chat .chat-details img{
    width: 35px;
    height: 35px;
    align-self: flex-start;
    object-fit: cover;
    border-radius: 2px;
}

.chat .chat-details p{
  margin: 0;
  padding: 0;
  padding: 0 50px 0 25px;
  font-size: 1.05rem;
  white-space: pre-wrap;
  word-break: break-word;
  color: #fff;
}

.typing-animation{
    display: inline-flex;
    padding-left: 25px;
}

.typing-animation .typing-dot{
  height: 7px;
  width: 7px;
  opacity: 0.7;
  margin: 0 3px;
  border-radius: 50%;
  background: #fff;
  animation: animateDots 1.5s var(--delay) ease-in-out infinite;
}

@keyframes animateDots{
    0%,44% {
        transform: translateY(0px);
    }
    22% {
        opacity: 0.4;
        transform: translateY(-6px);
    }

    44% {
        opacity: 0.2;
    }
}

/* typing container */

.typing-container{
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    padding: 20px 10px;
    justify-content: center;
    /* background:rgba(95, 96, 95, 0.255); */
    background: #050f05;
    /* border: 1px solid #2f2f2f; */
}

.typing-container .typing-content{
    max-width: 950px;
    width: 100%;
    display: flex;
    align-items: flex-end;
}

.typing-container .typing-textarea{
    width: 100%;
    display: flex;
    position: relative;
}

.typing-textarea textarea{
    width: 100%;
    height: 55px;
    border: none;
    border-radius: 2rem !important;
    outline: none;
    resize: none;
    font-size: 1rem;
    padding: 15px 45px 15px 20px;
    border-radius: 5px;
    background: #000; 
    color: #fff;
    max-height: 200px;
    overflow-y: auto;
}

.typing-textarea  textarea::placeholder{
    /* color: #2f2f2f; */
}

.typing-textarea span{
    position: absolute;
    right: 0;
    bottom: 0;
    /* visibility: hidden; */
}

/* .typing-textarea textarea:valid ~ span{
    visibility: visible;
} */

.typing-content span{
  height: 55px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ACACBE;
}

.typing-controls{
    display: flex;
}

.typing-controls span{
 margin-left: 7px;
 font-size: 1.4rem;
 border-radius: 4px;
 background: #2f2f2f;
 border: 1px solid #444654;
}

.animation-container{
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;
}

.animation-container .chip{
    max-width: 950px;
    max-height: 175px;
    overflow: auto;
    padding: 1rem;
}

.animation-container .typewriter h1 {
    overflow: hidden; 
    /* border-right: .15em solid green;  */
    font-weight: bolder;
    white-space: nowrap;
    margin: 0 auto; 
    letter-spacing: .15em; 
    animation: 
      typing 2s steps(40, end),
      blink-caret .75s step-end infinite;
  }

  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  /* @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: green; }
  } */

.animation-container .typing-content{
    max-width: 950px;
    width: 100%;
}

.animation-container .typing-textarea{
    position: relative;
    display: flex;
    width: 100%;
}

.animation-container .typing-textarea span{
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}