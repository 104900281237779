$adminSliderColor:#0000;

:root {
    --main-color: rgb(104, 117, 217);
    --main-color-hover: rgb(104, 117, 217, 0.2);
    --track-color: #ddd;
}

.customSlider {
    width: 100%;
    /* Optional: Only If you want to center your slider in the page */
    margin: auto;
}

.customSlider-track {
    /* Top value to align your track to the center of your thumb */
    top: 8px;
    /* thickness of the track */
    height: 4px;
    /* default color of your track */
    background: var(--track-color);
}

.customSlider-track.customSlider-track-0 {
    /* color of the track before the thumb */
    background: #003c49;
}

.customSlider-thumb {
    cursor: pointer;
    /*color for the thumb */
    background:  #003c49;
    /* shape of the thumb: circle */
    width: 20px;
    height: 20px;
    border-radius: 100%;
    /* remove default outline when selected */
    outline: none;
}

.customSlider-thumb:hover {
    box-shadow: 0 0 0 8px var(--main-color-hover);
}