$primary: #4bc641;
$secondary: #121d12;
$black : #000;
$white: #fff !default;
$box-shadow: 0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-sm: 0 .125rem .25rem rgba($black, .075) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black, .075) !default;

body {
  position: relative;
  background: #050f05;
  font-family: "Instrument Sans",
    sans-serif;
  color: #fff;
  // overflow-y: hidden;

  &::before {
    content: "";
    // background-image: url(../assets/images/avatars/bgLandingnew.png);
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: center top;
    opacity: 0.4;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .container {
    z-index: 12;
    position: relative;
  }
}

.header-bar {

  .logo {
    img {
      width: 120px;
    }
  }

  .btn {
    font-size: 14px;
  }

  .right {
    img {
      width: 33px;
    }
  }
}

.banner-area {
  max-width: 780px;
  text-align: center;
  padding-top: 4rem;
  margin: auto;

  .bnr-img {
    max-width: 290px;
  }

  h1 {
    font-size: 72px;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  h5 {
    font-size: 22px;
    line-height: 28px;
  }
}

.contentarea {
  padding: 4rem 3rem;

  .contentboxes {
    background: #121d12;
    border-radius: 32px;
    padding: 3rem;
    text-align: center;
    transition: all ease 0.25s;
    height: 100%;

    .icons {
      margin-bottom: 2rem;

      img {
        width: 48px;
      }
    }

    h5 {
      font-size: 34px;
      margin-bottom: 2rem;
    }

    &:hover {
      background: $primary;
    }
  }
}

@mixin media-breakpoint-down($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

.banner-area {
  @include media-breakpoint-down(md) {
    h1 {
      font-size: 36px;
    }

    h5 {
      font-size: 18px;
      line-height: 23px;
    }
  }
}

.text-primary {
  color: $primary !important;
}

.main-content {
  padding: 6.5rem 1rem 1rem 2.5rem;
  display: flex;
}

// .openbox {
//   .leftside-bar {
//     width: 250px;

//     .sidebar-menu {
//       .sidemenu-items {
//         span {
//           display: block;
//         }
//       }
//     }
//   }

//   .whizz-header {
//     left: 282px;
//     width: calc(100% - 296px);
//   }

//   .content-area {
//     padding-left: 247px;
//   }
// }

.header-bar {
  width: 100%;

  .left {
    h4 {
      font-size: 22px;
    }
  }
}

.content-area {
  padding-left: 80px;
  width: 100%;
  transition: all ease 0.25s;

  .upload-contents {
    margin-right: 1.2rem;
    width: calc(100% - 445px);

    .breif {
      background: $secondary;
      padding: 1.5rem;
      border-radius: 18px;
    }

    .upload-here {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 195px;
      text-align: center;
      position: relative;
      border-radius: 18px;
      border: dashed 1px #1d7c15;
      background: #000;

      input {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
      }

      .items {
        img {
          margin-bottom: 2rem;
          width: 35px;
        }
      }
    }
  }

  .chat-section {
    max-width: 430px;
    flex: 0 0 430px;
    background: $secondary;
    height: calc(100vh - 110px);
    border-radius: 18px;
    position: fixed;
    right: 15px;
    width: 100%;

    .chattitle {
      background: #1e2d1e;
      padding: 1.5rem 1rem;
      border-radius: 18px 18px 0 0;

      h5 {
        font-size: 17px;
      }
    }

    .chating-area {
      padding: 1rem 1.5rem;
      // height: calc(100vh - 266px);
      height: calc(100vh - 296px);
      overflow-y: auto;
      overflow-x: hidden;
    }

    .chat-send {
      text-align: center;

      .btn {
        padding-left: 1rem;
        padding-right: 1rem;
        color: #fff;
        min-width: 146px;
      }


    }

    .send {
      position: relative;

      .send-input {
        background: #000;
        border-radius: 18px;
        height: 54px;
        width: 100%;
        border: 0;
        color: #fff;
        padding: 1rem 4.5rem 1rem 1rem;

        &:focus {
          outline: none;
        }
      }

      .mic-icon {
        position: absolute;
        right: 50px;
        bottom: 16px;
        margin-right: 0px;
        background-color: transparent;
        outline: none;
        border: none;
      }

      .send-icon {
        position: absolute;
        right: 19px;
        bottom: 16px;
      }

      .send-btn-disable {
        opacity: 0.5;
        cursor: unset;
      }

      .send-btn {
        cursor: pointer;
      }
    }
  }
}

#myTextarea {
  resize: none; /* Prevent users from resizing the textarea manually */
  overflow-y: hidden; /* Hide the vertical scrollbar */
  min-height: 30px; /* Set a minimum height for the textarea */
  width: 100%; /* Ensure the textarea expands to fill its container */
  box-sizing: border-box; /* Include padding and border in the width and height */
}
.upload-items {
  display: flex;
  background: #000;
  border: solid 1px #383838;
  padding: 1.5rem;
  border-radius: 18px;

  .up-icon {
    margin-right: 1rem;

    img {
      width: 25px;
    }
  }

  .uploaded-data {
    width: 100%;
    align-items: flex-end;

    .progress-sc {
      width: 100%;
    }
  }

  &.uploded {
    .uploaded-data {
      align-items: center;
      word-break: break-all;

      a {
        color: #fff;
      }
    }
  }
}

.chatbox-icon {
  display: none;
}

@include media-breakpoint-down(lg) {
  .content-area {
    .chat-section {
      display: none;
    }

    .upload-contents {
      width: 100%;
      margin-right: 0;
    }
  }

  .chatbox-icon {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    background: $primary;
    border-radius: 100%;
    z-index: 12;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 29px 8px rgba(0, 0, 0, 0.58);

    img {
      width: 25px;
    }
  }
}

@include media-breakpoint-down(md) {
  .main-content {
    padding: 6.5rem 1rem 2rem 1rem;
  }

  .content-area {
    padding-left: 0;
  }
}

.hdr-main {
  padding: 0 0.65rem 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // @include transition(all 0.3s ease);

  .hdr-main-nav {
    ul {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      li {
        padding: 0 22px;

        a {
          padding: 0.75rem 0;
          text-decoration: none;
          font-weight: 600;
          color: #b7b7b7;

          &:hover,
          &:active,
          &.active {
            color: $primary;
          }

          &.active {
            border-bottom: solid 2px $primary;
          }
        }

        &.active a {
          color: $primary;
        }
      }
    }

    @include media-breakpoint-down(md) {
      position: fixed;
      top: -100%;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 3rem 2rem;
      background-color: $black;
      // @include transition(all 0.3s ease);

      &.open {
        top: 0;
      }

      ul {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100%;
        align-items: normal;

        li {
          padding: 0.55rem 22px;

          a {
            font-size: calc(1rem + 0.5vw);

            &:hover {
              color: $white;
            }

            &.active {
              color: $white;
              border-color: $white;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .skelton-img {
    height: calc(100vh - 380px);

  }

  .whizz-header {
    left: 14px;
    width: calc(100% - 30px);
  }

  .openbox {
    .whizz-header {
      left: 14px;
      width: calc(100% - 30px);
    }

    .content-area {
      padding-left: 0;
    }
  }
}

@mixin box-shadow($shadow) {
  box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
}

@mixin transition($properties) {
  -webkit-transition: $properties;
  -moz-transition: $properties;
  -ms-transition: $properties;
  -o-transition: $properties;
  transition: $properties;
}

.whizz-header {
  position: fixed;
  top: 1rem;
  left: 115px;
  background-color: #121d12;
  width: calc(100% - 130px);
  z-index: 1;
  @include transition(all 0.25s ease);
  border-radius: 18px;
  border: solid 1px #2b2b2b;

  &:before {
    content: '';
    width: 100%;
    height: 2rem;
    position: absolute;
    top: -33px;
    background: #050f05;
  }

  &.sticky {
    box-shadow: $box-shadow-sm;
    @include box-shadow(0 5px 12px rgba($black, 0.01));
    top: 0;
    padding: 17px 0;
  }

  .nav-items {
    &.right {
      .username {
        .namebox {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          background: #d6d6d6;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-weight: 600;
          color: $black;
        }
      }
    }

    .dropdown-menu {
      .dropdown-item {
        &:hover {
          background: $primary;
        }
      }
    }
  }
}

.hdr-main {
  padding: 0 0.65rem 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // @include transition(all 0.3s ease);

  .hdr-main-nav {
    ul {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      li {
        padding: 0 22px;

        a {
          padding: 0.75rem 0;
          text-decoration: none;
          font-weight: 600;
          color: #b7b7b7;

          &:hover,
          &:active,
          &.active {
            color: $primary;
          }

          &.active {
            border-bottom: solid 2px $primary;
          }
        }

        &.active a {
          color: $primary;
        }
      }
    }

    @include media-breakpoint-down(md) {
      position: fixed;
      top: -100%;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 3rem 2rem;
      background-color: $black;
      // @include transition(all 0.3s ease);

      &.open {
        top: 0;
      }

      ul {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100%;
        align-items: normal;

        li {
          padding: 0.55rem 22px;

          a {
            font-size: calc(1rem + 0.5vw);

            &:hover {
              color: $white;
            }

            &.active {
              color: $white;
              border-color: $white;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .whizz-header {
    left: 14px;
    width: calc(100% - 30px);
  }

  .openbox {
    .whizz-header {
      left: 14px;
      width: calc(100% - 30px);
    }

    .content-area {
      padding-left: 0;
    }
  }

}

// .leftside-bar .sidebar-menu {
//   padding-left: 1rem !important;
// }
.btn-sm,
.btn-group-sm>.btn {
  padding: 0.25rem 0.5rem;
  font-size: .875rem;
  border-radius: 100px;
}

.btn-primary {
  color: $black;
  background-color: $primary;
  border-color: $primary;

  &:hover {
    border: 1px solid $primary;
  }
}

.customBoardModalbg {
  background: #121d12;
  border: solid 1px #2b2b2b;
}

.cstmModalRadius {
  border-radius: 20px 20px 0 0;
}

.cstmModalRadiusBottom {
  border-radius: 0 0 20px 20px;
}

.customModalContent {
  background: transparent;
}

.modal-content {

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  pointer-events: auto;
  background-color: transparent !important;
  background-clip: padding-box;

  outline: 0;
}

.normalModalbg {
  background: #fff !important;
  color: black;
}

input[type="radio"] {
  cursor: pointer;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: #000;
  pointer-events: none;
  background-color: #3f4e3e;
  border-color: #358632;
  opacity: 0.6;
}

.glassEffect {
  background: #121d12 // backdrop-filter: inherit
}

.btn-close {
  --cui-btn-close-color: rgba(255, 255, 255, 0.95) !important;
  --cui-btn-close-bg: url(../assets/adminBoard/modalclose.svg) !important;
}

.cstm-transcript {
  font-size: 14px;
  text-decoration: underline;
  color: chartreuse;
  font-style: oblique;
  margin-left: 20px;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .openbox .whizz-header {
    left: 14px;
    width: calc(100% - 30px);
  }
}

@media (max-width: 991.98px) {
  .chatbox-icon {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    background: #4bc641;
    border-radius: 100%;
    z-index: 12;
    width: 55px;
    height: 55px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-shadow: 0px 0px 29px 8px rgb(0 0 0 / 58%);
    box-shadow: 0px 0px 29px 8px rgb(0 0 0 / 58%);
  }

  .chatbox-icon img {
    width: 25px;
  }
}



@media (max-width: 767.98px) {
  .expand-chat {
    left: 15px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .cstm-chat-height {
    height: calc(100vh - 321px) !important;
  }
}

@media (max-width: 991.98px) {
  .content-area .chat-section {
    /* max-width: 430px; */
    /* flex: 0 0 430px; */
    background: #121d12;
    height: calc(100vh - 190px);
    border-radius: 18px;
    position: fixed;
    right: 16px;
    width: 92%;
    transition: all ease 0.25s;
  }

  .content-area .chat-section .chating-area {
    padding: 1rem 1.5rem;
    height: calc(100vh - 346px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .cstm-chat-height {
    height: calc(100vh - 397px) !important;
  }
}

@media (min-width: 991.98px) {
  .cstm-chat-height-web {
    // height: calc(100vh - 321px) !important;
    height: calc(100vh - 340px) !important;
  }
}

.login-whizz {
  padding: 2rem;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-image: url('../assets/newLogin/bg.png');

  .login-boxes {
    background: #000;
    border-radius: 24px;
    padding: 3rem;
    box-shadow: -1px 0 43px -5px #3a8b3233;
    max-width: 650px;
    margin: auto;
  }

  .forgot-pswd {
    font-size: 14px;
    color: #4bc641;
  }

  .logobox {
    img {
      width: 200px;
    }
  }
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

.form-control {
  background: #0b1f0b;
  border: solid 1px #133213;
  padding: 5px 15px;
  min-height: 50px;
  border-radius: 14px;
  color: #fff;

  &:focus {
    outline: solid 1px #133213;
    background: #0b1f0b !important;
    color: #fff;
    border: solid 1px #133213;
  }
}



@media (min-width: 991.98px) {
  .openbox .content-area {
    padding-left: 247px;
  }


  .openbox .whizz-header {
    left: 282px;
    width: calc(100% - 296px);
  }
}



@media (max-width: 767.98px) {
  .expand-chat {
    left: 15px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .expand-chat {
    left: 323px !important;
  }
}

.skelton-img {
  height: calc(100vh - 330px);
  width: 100%;
  object-fit: cover;
}

.ctsm-whizzHeader {
  margin-left: 6px;
  font-weight: 700;
  font-size: 20px;
}

.chat-section {
  max-width: 430px;
  flex: 0 0 430px;
  background: $secondary;
  height: calc(100vh - 110px);
  border-radius: 18px;
  position: fixed;
  right: 15px;
  width: 100%;

  .chattitle {
    background: #1e2d1e;
    padding: 1.5rem 1rem;
    border-radius: 18px 18px 0 0;

    h5 {
      font-size: 17px;
    }
  }

  .chating-area {
    padding: 1rem 1.5rem;
    height: calc(100vh - 266px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .chat-send {
    text-align: center;

    .btn {
      padding-left: 1rem;
      padding-right: 1rem;
      color: #fff;
      min-width: 146px;
    }


  }

  .send {
    position: relative;

    .send-input {
      background: #000;
      border-radius: 18px;
      height: 54px;
      width: 100%;
      border: 0;
      color: #fff;
      padding: 1rem 3rem 1rem 1rem;

      &:focus {
        outline: none;
      }
    }

    .send-icon {
      position: absolute;
      right: 19px;
      bottom: 16px;
    }
  }
}